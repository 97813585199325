import { Link } from "gatsby"
import * as React from "react"
import { Container, Row } from "react-bootstrap"
import Banner from "../components/banner"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Banner title="404" />
    <section id="services" className="single-features pt-5 pb-5">
      <Container>
        <Row className="justify-content-center text-center">
          <div className="col-lg-10">
            <h1 className="text-center">Page Not Found</h1>
            <p class="rounded-buttons mt-5">
              <Link to="/" class="main-btn rounded-two">
                Go to Home
              </Link>
            </p>
          </div>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default NotFoundPage
